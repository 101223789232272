import * as React from 'react';
import { Component } from 'react';
import Logo from '../../public/logo.svg';
import '../common/common.scss';
import styles from './ErrorForm.module.scss';

class ErrorView extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      // @ts-ignore
      errorTitle: (document.getElementById('_error_title'))?.value,
      // @ts-ignore
      errorHint: (document.getElementById('_error_hint'))?.value?.split(','),
      // @ts-ignore
      errorDescription: (document.getElementById('_error_description'))?.value,
    };
  }

  render() {
    return (
      <div className={`card ${styles.errorCard}`}>
        <div className="card-body">
          <img src={Logo} alt="" className="logo" />
          <div className={styles.title}>{this.state.errorTitle}</div>
          {this.state.errorHint && 
            this.state.errorHint.map((hint : string) => 
            <div className={`error-text ${styles.errorText}`}>{hint}</div> )
          }
          <div className={styles.message}>{this.state.errorDescription}</div>
          <a href="mailto:support@ciphertrace.com?subject=Log in support" className="help">
            Need support?
          </a>
        </div>
      </div>
    );
  }
}

export default ErrorView;
