// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorForm-module__errorCard__JSe1t{white-space:pre-line}.ErrorForm-module__errorText__a32bW{text-align:center;margin-left:0}.ErrorForm-module__message__nRbJQ{padding:.75rem 0}.ErrorForm-module__title__H6P0T{padding:.75rem 0;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/error/ErrorForm.module.scss"],"names":[],"mappings":"AAEA,oCACE,oBAAA,CAGF,oCACE,iBAAA,CACA,aAAA,CAGF,kCACE,gBAAA,CAGF,gCACE,gBAAA,CACA,gBAAA","sourcesContent":["@import '~styles/sizes';\n\n.errorCard {\n  white-space: pre-line;\n}\n\n.errorText {\n  text-align: center;\n  margin-left: 0;\n}\n\n.message {\n  padding: 0.75rem 0;\n}\n\n.title {\n  padding: 0.75rem 0;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorCard": "ErrorForm-module__errorCard__JSe1t",
	"errorText": "ErrorForm-module__errorText__a32bW",
	"message": "ErrorForm-module__message__nRbJQ",
	"title": "ErrorForm-module__title__H6P0T"
};
export default ___CSS_LOADER_EXPORT___;
